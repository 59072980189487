@tailwind base;

@tailwind components;

@tailwind utilities;

body {
  @apply m-0 font-sans antialiased text-gray-900 bg-gray-100;
}

code {
  @apply font-mono;
}



/* Profil-Container-Stil */
.profile-container {
  @apply shadow overflow-hidden rounded-lg;
}

/* Profil-Header */
.profile-header {
  @apply px-4 py-5 px-6 flex justify-between items-center;
}

/* Profil-Header-Informationen */
.profile-header-info {
  @apply flex items-center;
}

/* Benutzer-Avatar-Stil */
.user-avatar {
  @apply h-12 w-12 rounded-full;
}

/* Profil-Informationen */
.profile-info {
  @apply ml-4;
}

/* Profil-Bearbeiten-Link */
.edit-profile-link {
  @apply text-indigo-600 text-indigo-900;
}

.dropdown {
  @apply absolute right-0 mt-2 py-2 w-48 bg-white rounded-md shadow-xl z-20 text-center;
}

.logout-button {
  @apply text-white bg-red-500 bg-red-700 font-bold py-2 px-4 rounded w-full;
}

/* Profilbild- und Link-Stile */
.profile-link {
  @apply text-white pr-3 pr-4 no-underline underline;
}

.user-avatar {
  @apply inline-block h-10 w-10 rounded-full align-middle;
}

/* Kartenstil für Statistiken */
.card {
  @apply p-4 rounded-lg shadow-md shadow-lg transition-shadow duration-300;
}

/* Icon-Stil */
.icon {
  @apply mr-2 text-lg;
}




